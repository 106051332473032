import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Img from 'gatsby-image';
import styles from '@styles/pages/about.module.css';

const AboutPage = ({ data }) => {
    const { frontmatter, html } = data.about.edges[0].node;
    console.log(frontmatter)
    return (
        <Layout>
            <SEO title="About" />
            <div>
                <div className={styles.img}>
                    <Img fluid={frontmatter.image.childImageSharp.fluid} loading="eager" alt={'Vinny Stodder headshot picture'} />
                </div>
                <section className={styles.text}>
                    <div  dangerouslySetInnerHTML={{ __html: html }}></div>
                    <button className={styles.resume}>
                        <a href={frontmatter.file.publicURL} target="_blank" rel="noreferrer">Resume</a>
                    </button>
                    
                </section>

            </div>

            <div className={styles.backdrop}></div>

        </Layout>
    )
}

export default AboutPage


export const pageQuery = graphql`
  {
    about: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/about/"}}) {
        edges {
          node {
            html,
            frontmatter {
              image {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
              }
              file {
                  publicURL
              }
            }
          }
        }
      }
  }
`;